.ant-picker-cell-in-view .select {
  border-top: 2px solid #30a694;
  background: #e3f3ee;
  box-shadow: 1px 0px 2px 1px #e1e8e7;
  color: #30a694;
}

.ant-picker-cell-in-view .un-select {
  border-top: 2px solid #ccc;
  background: white;
  box-shadow: 1px 0px 2px 1px #eee;
  color: #333;
}

.ant-picker-cell-in-view .box {
  width: 95%;
  height: 78px;
  box-sizing: border-box;
  padding: 8px;
}
