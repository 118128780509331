@import '~antd/lib/style/themes/default.less';
@import 'antd/dist/antd.less';

@primary-color: #30A694; // 全局主色

body {
  min-height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.g-body {
  display: flex;
  min-height: 100%;
}

.g-nav {
  height: 100%;
  border-right: 1px solid #f0f0f0;
  box-shadow: 2px 0px 5px 0px #ccc;
  background: white;
  position: fixed;
  z-index: 3;
  overflow-y: auto;
}

.g-main {
  position: relative;
  min-width: 1136px;
  width: 100%;
  background-color: rgba(240, 245, 245, 1);
  border-bottom: 1px solid rgba(240, 245, 245, 1);
  position: relative;
  z-index: 2;
}

.bizcharts-tooltip {
  background-color: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}



// Table Styles
.ant-table-cell {
  vertical-align: middle;
}

.thead-center {
  text-align: center !important;
}

// Modal
.ant-popover {
  z-index: 1000 !important;
}

.ant-picker-content th {
font-weight: bold !important;
}

