.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 10;
}

.row-dragging td {
  padding: 16px;
  /* visibility: hidden; */
}

.row-dragging .drag-visible {
  visibility: visible;
}