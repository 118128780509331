body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;

  padding: 0;

  border: 0;

  outline: 0;

  font-size: 100%;

  vertical-align: baseline;

  background: transparent;

  outline-style: none; /*FF*/
}

  body {
  line-height: 1;
}

a {
  margin: 0;

  padding: 0;

  border: 0;

  font-size: 100%;

  vertical-align: baseline;

  background: transparent;
}

a:hover,
a:focus {
  text-decoration: none;

  bblr: expression(this.onFocus=this.blur()); /*IE*/

  outline-style: none; /*FF*/
}

table {
  border-collapse: collapse;

  border-spacing: 0;
}

input,
select {
  vertical-align: middle;
}

/*css为clearfix，清除浮动*/

.clearfix::before,
.clearfix::after {
  content: "";

  height: 0;

  line-height: 0;

  display: block;

  visibility: hidden;

  clear: both;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1; /*IE/7/6*/
}

::-webkit-scrollbar {
  display: none;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
